@import '~@jutro/theme/assets/sass/helpers';
@import '../left-nav/left-nav.module.scss';

$text-color-white: #fff;

.display-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerMainContainer {
  background-color: $text-color-white;
  background: $text-color-white 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0rem 1rem #00050059;
}

.osbcHeaderMainContainer {
  box-shadow: 0px 10px 10px 0px #e3e3e3;
  margin-bottom: 20px;
}

.osbcHeaderContainer {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.iAmLabel {
  text-align: right;
  width: inherit;
  font-size: 14px;
  font-weight: 600;
}

.headerContainer {
  padding: 0rem 20px;
  margin: 0rem auto;
  max-width: 1448px;
}

.headerMainLogoContainer {
  @extend .display-flex-row;
  justify-content: flex-start;
}

.headerLogoContainer {
  width: 130px;
}

.headerLogo {
  width: 100%;
  height: 65px;
  display: inline-block;
}

.headerLogo:focus-visible,
.headerLogo:focus{
  outline: none;
  box-shadow: 0 0 0px 2px #000 !important;
}

.headerLabel {
  font-size: 36px;
  color: #333333;
  padding-left: 1.25rem;
}

.headerLinksContainer {
  @extend .display-flex-row;
  width: 100%;
  height: auto;
  padding-top: 0.5rem;
  justify-content: flex-end;
  align-items: baseline;
}

.headerDropDown {
  letter-spacing: 0rem;
  color: #333333;
  margin-left: -15px;
  margin-right: 75px;
  width: 150px !important;
  --generic-select-control-font-size: 14px !important;
  --generic-select-control-border-width: 0px !important;
  --generic-select-control-bg-color: transparent !important;
  font-weight: 600;
  white-space: nowrap;
}

.headerHyperLinks,
.headerHyperLinks:hover,
.headerHyperLinks:active,
.headerHyperLinks:active:focus{
  color: #333333;
  padding-right: 0px;
  opacity: 1;
  box-sizing: none;
  border-radius: unset;
  background: none;
  box-shadow: none;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  --button-active-color: #333;
  --button-active-bg-color: transparent;
  --button-active-bg-image: transparent;
  --button-active-border-color: transparent;
  white-space: nowrap;
  padding-left: 24px;
}

.headerHyperLinks:focus{
  color: #333333;
  padding-right: 0px;
  opacity: 1;
  box-sizing: none;
  border-radius: unset;
  background: none;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  --button-active-color: #333;
  --button-active-bg-color: transparent;
  --button-active-bg-image: transparent;
  --button-active-border-color: transparent;
  white-space: nowrap;
  padding-left: 24px;
}

.headerHyperLinks:hover {
  text-decoration: underline !important;
}

.headerHyperLinks{
  border: 1px solid #fff !important;
}

.headerLoggedInUserNameContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
  height: 50px; 
}

.headerLoggedInUserName {
  font-size: 24px;
  color: #333333;
  border-bottom: 8px solid #008942;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  overflow: hidden;
}

.osbcHeaderResponsiveMainContainer {
  background-color: var(--GW-COLOR-BACKGROUND-1);
  background: $text-color-white 0% 0% no-repeat padding-box;
}

.osbcHeaderResponsiveContainer {
  @extend .display-flex-row;
  justify-content: flex-start;
}

.headerMenuContainer {
  text-align: right;
}

.iconButtons {
  padding: 0rem;
  background: none;
}

.responsiveHeaderContainer,
.modalContainer,
.modalBackDrop {
  display: none;
}

@include gw-breakpoint-down(tablet) {
  .menuButtonHeader {
    padding: 0px;
  }

  .osbcHeaderContainer {
    display: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }

  .responsiveHeaderContainer {
    display: block;
    width: 100%;
    padding: 10px 0px;
  }

  .responsiveHeader {
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 24px;
  }

  .headerLogoContainer {
    height: 50px;
    // margin-left: 12.5%;
  }
  .headerLogo {
    width: 100%;
    height: 50px;
    background-position: center;
  }

  .fas .fa-times {
    text-align: right;
    margin: 0rem;
    font-size: 16px;
    color: $text-color-white;
  }

  .headerMenuContainer {
    width: 100%;
  }

  .responsiveHyperLinks,
  .responsiveHyperLinks:hover,
  .responsiveHyperLinks:active,
  .responsiveHyperLinks:active:focus,
  .responsiveHyperLinks:focus {
    color: #333333;
    border-radius: 0px;
    border-bottom: none;
    background: none;
    box-shadow: none;
    font-weight: 600;
    border: none !important;
  }

  .headerHyperLinks:hover,
  .responsiveHyperLinks:hover {
    text-decoration: none;
  }

  .menuIconClicked {
    padding: 0rem;
    font-size: 16px;
  }

  .modalBackDrop {
    display: block;
    position: fixed;
    cursor: none;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 0.3;
    left: 0;
    top: 0;
  }
  .modalContainer {
    display: block;
    z-index: 300;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100vh;
    background: #00803e 0% 0% no-repeat padding-box;
    color: $text-color-white;
    padding-top: 20px;
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    overflow: auto;
    -webkit-overflow-scrolling : touch !important;
    // max-height: 100vh;
  }
  .modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px;
    padding-bottom: 0px;
    border-bottom: 2px solid $text-color-white;
  }
  .modalHeaderTopContiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 25px 0px;
    justify-content: space-between;
    padding-top: 0px;
  }
  .headerLoggedInUserName {
    color: $text-color-white;
    font-size: 16px;
    align-self: flex-end;
    border-bottom: none;
    padding: 20px 0px;
    padding-top: 0px;
    text-align: left;
    white-space: nowrap;
  width: 300px;
  overflow: hidden;
  }
  .modalContentContainer {
    padding-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column nowrap;
    text-align: center;
  }

  .dropdowmContainerCLass {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid;
    width: 85% !important;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .headerDropDown {
    width: 100% !important;
    margin-left: 0px;
    --GW-COLOR-TEXT-1: #ffffff !important;
    --generic-select-control-font-size: 16px !important;
    --generic-select-control-color: !important;
    --GW-Z-INDEX-HIGHER: 9999 !important;
    --GW-COLOR-BACKGROUND-1: transparent !important;
  }

  .iAmLabel {
    text-align: left;
    width: 23%;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
  }

  .menuIcon {
    font-size: 16px;
    color: $text-color-white;
  }
  .borderBottomWhite {
    width: 100%;
  }

  .headerHyperLinks,
  .headerHyperLinks:hover,
  .headerHyperLinks:active,
  .headerHyperLinks:focus,
  .headerHyperLinks:active:focus {
    color: #fff !important;
    width: 100%;
    height: auto;
    font-weight: 600;
    border-radius: 0px;
    text-align: left;
    font-size: 16px;
    line-height: 40px;
    background-color: transparent !important;
  }

  .borderBottomWhite button {
    padding: 25px;
    padding-left: 0px;
  }
  .borderBottomWhite a,
  .borderBottomWhite button {
    border: unset !important;
    border-bottom: 2px solid #fff !important;
    border-radius: 0px;
    width: 85% !important;
  }
  .borderBottomWhite a,
  .borderBottomWhite div a,
  .borderBottomWhite a:hover,
  .borderBottomWhite a:active,
  .borderBottomWhite a:active:focus,
  .borderBottomWhite a:focus {
    padding: 25px;
    text-align: left;
    padding-left: 0px;
    justify-content: unset !important;
  }

  .borderBottomWhite {
    a:active {
      border-left: 10px solid #fff !important;
      border-radius: 0px !important;
      width: 100% !important;
      padding-left: 30px;
    }
  }

}

@include gw-breakpoint-only(phone) {
  .modalContainer {
    width: 75%;
  }
  .osbcHeaderContainer {
    display: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .headerLoggedInUserName {
    color: $text-color-white;
    font-size: 16px;
    align-self: flex-end;
    border-bottom: none;
    padding: 20px 0px;
    padding-top: 0px;
    text-align: left;
    white-space: nowrap;
  width: 200px;
  overflow: hidden;
  }
}

.responsiveSidenav {
  display: block !important;
  width: 100%;
}
.tabcontiner {
  border-bottom: 1px solid #BBBAB9;
  padding:16px 20px;
  font-weight: normal;
  word-wrap: break-word;
  display: flex;
  text-align: left;
  color:#333333;
  cursor:pointer;
  background-color: #fff;
  --link-color:#333333;
  --GW-FONT-SIZE: 16px;
  --GW-COLOR-LINK-1: #333333;
  --GW-COLOR-LINK-1-HOVER: #333333;
  --GW-COLOR-LINK-1-ACTIVE: #333333;
}
.catagory {
  padding: 16px 0px 10px 20px;
  font-weight: 600;
  font-size: 16px;
}
.subcatagory:hover{
  background-color: #E5F1F8 ;
}
.icon{
  color:#0076BF;
  font-size:16px;
  padding-right:17px;
}
.active {
  background-color: #fff!important
}
.active a{
  background-color: #fff!important
}

.tabcontiner{
  margin: 0px !important;
  width: 100% !important;
}

.paddingRight{
  padding-right: 30px;
}

.unsupportedBanner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: #003359;
    padding: 1.5em 0;
    margin-bottom: 60px;
    div {
        padding: .5em 0;
    }
}
@media screen and (max-width: 625px) {
    .unsupportedBanner {
        padding: 1em 0;
    }
}
@media screen and (max-width: 316px) {
    .unsupportedBanner {
        padding: .4em 0;
    }
}
.bannerContent {
    color: #FFFFFF;
    padding: .5em 0;
}
.unsupportedIcon {
    margin-right: 16px;
    i {
        color: #FFFFFF;
        padding: 0;
    }
}

@media screen and (max-width: 750px) {
    .headerLogoContainer {
      margin-left: auto;
    }
}